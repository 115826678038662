import React from 'react'
import { Link } from 'react-router-dom'

function Sidebar() {
    const toggleSidebar = () => {
        const body = document.body;
        let classNameToAddOrRemove = 'toggle-sidebar';
        if (/Mobi|Android/i.test(navigator.userAgent)) {
            
            if (body.classList.contains(classNameToAddOrRemove)) {
                // If it's present, remove the class
                body.classList.remove(classNameToAddOrRemove);
            } else {
                // If it's not present, add the class
                body.classList.add(classNameToAddOrRemove);
            }
          }
    }
    return (

        <aside id="sidebar" className="sidebar">

            <ul className="sidebar-nav" id="sidebar-nav">

                <li className="nav-item">
                    <Link className="nav-link " to="/admin/dashboard" onClick={toggleSidebar}>
                        <i className="bi bi-grid"></i>
                        <span style={{'color':'#012970'}}>Dashboard</span>
                    </Link>
                </li>
             

                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav1" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide"  style={{'color':'#f4c056'}}></i><span>Profile</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav1" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/update-profile" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Edit Profile</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/update-password" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Change Password</span>
                            </Link>
                        </li>




                    </ul>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav2" data-bs-toggle="collapse" href="#">
                        <i className="bx bx-map"  style={{'color':'#f4c056'}}></i><span>Destinations</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav2" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/destinations" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Destination List</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/create-destinations" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add Destination</span>
                            </Link>
                        </li>




                    </ul>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav3" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide"  style={{'color':'#f4c056'}}></i><span>Categories</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav3" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/categories" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Category List</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/create-category" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add Category</span>
                            </Link>
                        </li>




                    </ul>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav4" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-building"  style={{'color':'#f4c056'}}></i><span>Hotel Settings</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav4" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/facilities" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Hotel Facility List</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/create-facility" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add Hotel Facility</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/hotels" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Hotels List</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/create-hotel" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add Hotel</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/hotel-gallery-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Hotels Gallery</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/hotel-faq-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Hotels FAQ</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/hotel-review-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Hotels Review</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/admin/room-types" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Hotel Room Types</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/create-room-type" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add Room Type</span>
                            </Link>
                        </li> */}
                        <li>
                            <Link to="/admin/hotel-room-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Hotel Room List</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/hotel-room" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add Hotel Room</span>
                            </Link>
                        </li>




                    </ul>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav5" data-bs-toggle="collapse" href="#">
                        <i className="ri-home-4-line"  style={{'color':'#f4c056'}}></i><span>Home page</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav5" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/update-aboutus" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>About Us</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/add-banners" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add banners</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/banner-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Banner list</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/hotel-videos" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add hotel videos</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/hotel-video-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>hotel videos list</span>
                            </Link>
                        </li>
                      




                    </ul>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav9" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide"  style={{'color':'#f4c056'}} ></i><span>Partners</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav9" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/company-client" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add  Partners</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/company-client-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Partners List</span>
                            </Link>
                        </li>




                    </ul>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav6" data-bs-toggle="collapse" href="#">
                        <i className="ri-sailboat-fill"  style={{'color':'#f4c056'}}></i><span>Pages</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav6" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                    <li>
                            <Link to="/admin/page-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Pages List</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/create-page" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Create page</span>
                            </Link>
                        </li>
                      




                    </ul>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav8" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-grid"  style={{'color':'#f4c056'}}></i><span>Blogs</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav8" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                    <li>
                            <Link to="/admin/blog-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Blog List</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/create-blog" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Create Blog</span>
                            </Link>
                        </li>
                      




                    </ul>
                </li>
                <li className="nav-item">
                    <Link className="nav-link " to="/admin/enquiry" onClick={toggleSidebar}>
                        <i className="bi bi-grid"></i>
                        <span style={{'color':'#012970'}}>Quick Enquiry</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link " to="/admin/booking" onClick={toggleSidebar}>
                        <i className="bi bi-grid"></i>
                        <span style={{'color':'#012970'}}>Booking</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav7" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-grid"  style={{'color':'#f4c056'}}></i><span>Footer Management</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav7" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                    <li>
                            <Link to="/admin/footers" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Footer List</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/create-footer" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Create Footer</span>
                            </Link>
                        </li>
                      




                    </ul>
                </li>
             





            </ul>

        </aside>

    )
}

export default Sidebar