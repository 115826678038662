import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import SidebarLayout from '../layout/SidebarLayout';
import UserLayout from '../layout/UserLayout';
const Loader = (Component) => (props) =>
(
    <Suspense >
        <Component {...props} />
    </Suspense>
);
const LoginPage = Loader(lazy(() => import('../pages/LoginPage')));
const ErrorPage = Loader(lazy(() => import('../pages/ErrorPage')));
const Dashboard =Loader(lazy(() => import('../pages/Dashboard')));
const UpdatePassword = Loader(lazy(() => import('../pages/UpdatePassword')));
const UpdateProfile = Loader(lazy(() => import('../pages/UpdateProfile')));
const DestinationCreateEdit =Loader(lazy(()=> import('../pages/destinations/CreateEdit')));
const DestinationList =Loader(lazy(()=> import('../pages/destinations/List')));
const CategoryCreateEdit =Loader(lazy(()=> import('../pages/category/CreateEdit')));
const CategoryList =Loader(lazy(()=> import('../pages/category/List')));
const HotelFacilityCreateEdit =Loader(lazy(()=> import('../pages/HotelFacility/CreateEdit')));
const HotelFacilityList =Loader(lazy(()=> import('../pages/HotelFacility/List')));
const HotelCreateEdit =Loader(lazy(()=> import('../pages/Hotels/CreateEdit')));
const HotelList =Loader(lazy(()=> import('../pages/Hotels/List')));
const Aboutus =Loader(lazy(()=> import('../pages/about/CreateEdit')));
const HouseBoatCreateEdit =Loader(lazy(()=> import('../pages/HouseBoat/CreateEdit')));
const HouseBoatList =Loader(lazy(()=> import('../pages/HouseBoat/List')));
const TourPackageCreateEdit =Loader(lazy(()=> import('../pages/TourPackage/CreateEdit')));
const TourPackageList =Loader(lazy(()=> import('../pages/TourPackage/List')));
const BannerCreateEdit =Loader(lazy(()=> import('../pages/Banner/CreateEdit')));
const BannerList =Loader(lazy(()=> import('../pages/Banner/List')));
const GalleryList =Loader(lazy(()=> import('../pages/HotelGallery/List')));
const HotelRoomCreateEdit =Loader(lazy(()=> import('../pages/HotelRoom/CreateEdit')));
const HotelRoomList =Loader(lazy(()=> import('../pages/HotelRoom/List')));
const PageList =Loader(lazy(()=> import('../pages/extra-pages/List')));
const PageCreate =Loader(lazy(()=> import('../pages/extra-pages/CreateEdit')));
const QuickEnquiryList =Loader(lazy(()=> import('../pages/Enquiry/List')));
const BookingList =Loader(lazy(()=> import('../pages/Booking/List')));
const FooterList =Loader(lazy(()=> import('../pages/FooterManagement/List')));
const FooterCreate =Loader(lazy(()=> import('../pages/FooterManagement/CreateEdit')));
const HotelFaqList =Loader(lazy(()=> import('../pages/HotelFaq/List')));
const HotelReviewList =Loader(lazy(()=> import('../pages/Review/List')));
const BlogList =Loader(lazy(()=> import('../pages/Blogs/List')));
const BlogCreate =Loader(lazy(()=> import('../pages/Blogs/CreateEdit')));
const CompanyClientList = Loader(lazy(() => import('../pages/CompanyClient/List')));
const CompanyClientCreateEdit = Loader(lazy(() => import('../pages/CompanyClient/CreateEdit')));
const HotelVideoList = Loader(lazy(() => import('../pages/HotelVideos/List')));
const HotelVideoCreateEdit = Loader(lazy(() => import('../pages/HotelVideos/CreateEdit')));
const HotelRoomTypeList = Loader(lazy(() => import('../pages/RoomType/List')));
const HotelRoomTypeCreateEdit = Loader(lazy(() => import('../pages/RoomType/CreateEdit')));

function External() {
    window.location.href = '/home.html';
    return null;
  }

const routes = (isLoggedIn) => [
    {
        path: '/',
        element:isLoggedIn?<Navigate to="/admin/dashboard" />:<Navigate to="/login" />,

    },
    {
        path: '/404',
        element: <ErrorPage />,

    },  
    {
        path: '/login',
        element: <LoginPage />,

    },
    {
        path: '/admin',
        element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/login" />,
        children: [
            { element: <Navigate to="/admin/dashboard" replace /> },
            {
                path:"dashboard",
                element: <Dashboard />,
            },
            {
                path:"update-password",
                element: <UpdatePassword/>,
            },
            {
                path:"update-profile",
                element: <UpdateProfile/>,
            },
            {
                path:"destinations",
                element: <DestinationList/>,
            },
            {
                path:"create-destinations",
                element: <DestinationCreateEdit/>,
            },
            {
                path:"categories",
                element: <CategoryList/>,
            },
            {
                path:"create-category",
                element: <CategoryCreateEdit/>,
            },
            {
                path:"facilities",
                element: <HotelFacilityList/>,
            },
            {
                path:"create-facility",
                element: <HotelFacilityCreateEdit/>,
            },
            {
                path:"room-types",
                element: <HotelRoomTypeList/>,
            },
            {
                path:"create-room-type",
                element: <HotelRoomTypeCreateEdit/>,
            },
            {
                path:"hotels",
                element: <HotelList/>,
            },
            {
                path:"create-hotel",
                element: <HotelCreateEdit/>,
            },
            {
                path:"update-aboutus",
                element: <Aboutus/>,
            },
            {
                path:"house-boat-list",
                element: <HouseBoatList/>,
            },
            {
                path:"house-boat",
                element: <HouseBoatCreateEdit/>,
            },
            {
                path:"tour-package-list",
                element: <TourPackageList/>,
            },
            {
                path:"tour-package",
                element: <TourPackageCreateEdit/>,
            },
            {
                path:"add-banners",
                element: <BannerCreateEdit/>,
            },
            {
                path:"banner-list",
                element: <BannerList/>,
            },
            {
                path:"hotel-gallery-list",
                element:<GalleryList/>
            },
            {
               path:"hotel-faq-list",
               element:<HotelFaqList/>
            },
            { 
                path:"hotel-review-list",
                element:<HotelReviewList/>
            },
            {
                path:"hotel-room",
                element: <HotelRoomCreateEdit/>,
            },
            {
                path:"hotel-room-list",
                element:<HotelRoomList/>
            },
            {
                path:"create-page",
                element: <PageCreate/>,
            },
            {
                path:"page-list",
                element:<PageList/>
            },
            {
                path:"create-blog",
                element: <BlogCreate/>,
            },
            {
                path:"blog-list",
                element:<BlogList/>
            },
            {
                path:'enquiry',
                element:<QuickEnquiryList/>
            },
            {
                path:'booking',
                element:<BookingList/>
            },
            {
                path:"create-footer",
                element: <FooterCreate/>,
            },
            {
                path:"footers",
                element:<FooterList/>
            },
            {
                path:"company-client",
                element: <CompanyClientCreateEdit/>,
            },
            {
                path:"company-client-list",
                element: <CompanyClientList/>,
            },
            {
                path:"hotel-videos",
                element: <HotelVideoCreateEdit/>,
            },
            {
                path:"hotel-video-list",
                element: <HotelVideoList/>,
            },
            
            

        ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
];

export default routes;